import React, { Fragment } from 'react';
import styles from './styles.module.scss';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import Header from '@components/Header';
import GoingToUniBanner from '@components/GoingToUniBanner';

const GoingToUni2 = ({
    data: {
        page: {
            frontmatter: { title, items, description, heroGraphic },
        },
    },
}) => {
    return (
        <Layout>
            <SEO title={`Going to uni`} />
            <GoingToUniBanner />
            <Divider margin={4} />
            <Divider margin={4} />
            <div className={styles.uniWrapper}>
                <div className={styles.uniSectionContainer}>
                    <Grid>
                        {items?.map((item, index) => (
                            <Fragment key={index}>
                                {!!index && (
                                    <Fragment>
                                        <Divider margin={4} />
                                        <Divider margin={4} />
                                    </Fragment>
                                )}
                                <Row>
                                    <Col xs={12} xl={6} shrink>
                                        <Divider xsMargin={2} smMargin={3} xlMargin={0} />
                                        <div
                                            className={`${styles?.uniSectionTextContainer} ${
                                                index === 0 || index % 2 === 0
                                                    ? styles.rightText
                                                    : styles.leftText
                                            }`}
                                        >
                                            {!!item?.title && <h3>{item?.title}</h3>}
                                            {!!item?.text && (
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: item?.text }}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                    <Col xs={12} xl={6} first="xs" last={index % 2 !== 0 && 'xl'}>
                                        <div className={styles.uniSectionImageContainer}>
                                            <Img
                                                fluid={item?.image?.childImageSharp?.fluid}
                                                alt="dan and jess"
                                                // imgStyle={{ objectFit: 'contain', borderRadius: '15px' }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Fragment>
                        ))}
                    </Grid>
                </div>
            </div>
            <Divider />
            <Divider />
        </Layout>
    );
};

export default GoingToUni2;

export const query = graphql`
    query UniPage($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: uni_title
                description: uni_description
                heroGraphic: uni_hero_graphic {
                    publicURL
                }
                items: uni_items {
                    text: uni_items_item_text
                    title: uni_items_item_title
                    image: uni_items_item_image {
                        childImageSharp {
                            fluid(maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
