import React, { Fragment } from 'react';
import styles from './styles.module.scss';
import Button from '@components/Button';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import { graphql, useStaticQuery } from 'gatsby';

const GoingToUniBanner = ({ displayButton = false }) => {
    const {
        banner: {
            frontmatter: { title, description, graphic },
        },
    } = useStaticQuery(graphql`
        query UniBanner {
            banner: markdownRemark(fileAbsolutePath: { regex: "/going-to-uni/" }) {
                frontmatter {
                    title: uni_title
                    description: uni_description
                    graphic: uni_hero_graphic {
                        publicURL
                    }
                }
            }
        }
    `);

    return (
        <div>
            <div className={styles.uniHeader}>
                <Grid>
                    <Row>
                        <Col sm={6} xs={12}>
                            {displayButton ? <h2>{title}</h2> : <h1>{title}</h1>}
                            <Divider />
                            <div
                                className={styles.description}
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                            {displayButton && <Button text="Read more" link="/going-to-uni" />}
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className={styles.graphicUniContainer}>
                                <img src={graphic?.publicURL} alt="lamp graphic" />
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div>
    );
};

export default GoingToUniBanner;
